<template>
    <v-row class="gst-static-not-found u-height-100">
        <v-col cols="12"
            align="center"
            justify="center">
            <ImagePublicAssets :path-key="notFoundSrc" height="300px" contain />
            <p>
                {{ $t('message') }}
            </p>
            <BaseButton
                class="gst-static-not-found__button"
                :placeholder="$t('_common:buttons.backToHome')"
                :to="'/'" />
        </v-col>
    </v-row>
</template>
<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import ImagePublicAssets from '@core/shared/components/images/ImagePublicAssets.vue';

    export default {
        name: 'NotFound',
        components: {
            BaseButton,
            ImagePublicAssets
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'static.notFound'
        },
        data( ) {
            return {
                loading: true
            };
        },
        computed: {
            notFoundSrc() {
                return 'FALLBACKS.NOT_FOUND';
            }
        }
    };
</script>
